import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { SagaIterator } from '@redux-saga/core';
import { APICore, setAuthorization } from 'helpers/api/apiCore';
import {
    login as loginApi,
    logout as logoutApi,
    signup as signupApi,
    forgotPassword as forgotPasswordApi,
} from 'helpers';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';

type UserData = {
    payload: {
        first_name: string;
        middle_name: string;
        last_name: string;
        birthday: Date;
        country: string;
        state: string;
        county: string;
        city: string;
        zip_code: string;
        building_number: number;
        street_name: string;
        unit: string;
        photo_url: string;
        news_agency1: string;
        agency_zipcode1: string;
        media_type1: string;
        agency_email1: string;
        news_agency2: string;
        agency_zipcode2: string;
        media_type2: string;
        agency_email2: string;
        news_agency3: string;
        agency_zipcode3: string;
        media_type3: string;
        agency_email3: string;
        email: string;
        password: string;
        role: string
    };
    type: string;
};

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, password, role }, type }: UserData): SagaIterator {
    try {
        const response = yield call(loginApi, { email, password, role });
        const user = response.data;
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

/**
 * Logout the user
 */
function* logout(): SagaIterator {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

function* signup({ payload: {
    first_name,
    middle_name,
    last_name,
    birthday,
    country,
    state,
    county,
    city,
    zip_code,
    building_number,
    street_name,
    unit,
    photo_url,
    news_agency1,
    agency_zipcode1,
    media_type1,
    agency_email1,
    news_agency2,
    agency_zipcode2,
    media_type2,
    agency_email2,
    news_agency3,
    agency_zipcode3,
    media_type3,
    agency_email3,
    email,
    password, } }: UserData): SagaIterator {
    try {
        const response = yield call(signupApi, {
            first_name,
            middle_name,
            last_name,
            birthday,
            country,
            state,
            county,
            city,
            zip_code,
            building_number,
            street_name,
            unit,
            photo_url,
            news_agency1,
            agency_zipcode1,
            media_type1,
            agency_email1,
            news_agency2,
            agency_zipcode2,
            media_type2,
            agency_email2,
            news_agency3,
            agency_zipcode3,
            media_type3,
            agency_email3,
            email,
            password,
        });
        const user = response.data;
        // api.setLoggedInUser(user);
        // setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }: UserData): SagaIterator {
    try {
        const response = yield call(forgotPasswordApi, { email });
        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data));
    } catch (error: any) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}
export function* watchLoginUser() {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
    yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword() {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

function* authSaga() {
    yield all([fork(watchLoginUser), fork(watchLogout), fork(watchSignup), fork(watchForgotPassword)]);
}

export default authSaga;
