export type MenuItemType = {
    key: string;
    label: string;
    isTitle?: boolean;
    icon?: string;
    url?: string;
    badge?: {
        variant: string;
        text: string;
    };
    parentKey?: string;
    target?: string;
    children?: MenuItemType[];
};

const GUEST_MENU_ITEMS: MenuItemType[] = [
    // { key: 'navigation', label: 'Navigation', isTitle: true },
    { key: 'login', label: 'Log In', url: '/account/login'},
    { key: 'register_player', label: 'Register to Player', url: '/account/register'},
    { key: 'become_sponsor', label: 'Become a Sponsor', url: '/account/become_sponsor'},
    { key: 'news', label: 'News', url: '/welcome'},
    { key: 'starting_contest', label: 'Contest Starting Soon', url: '/conteststarting'},
    { key: 'contest_finished', label: 'Contests Finished', url: '/contestfinished'},
];

const PLAYER_MENU_ITEMS: MenuItemType[] = [
    { key: 'account', label: 'Account', url: '/player/account'},
    { key: 'news', label: 'News', url: '/player/news'},
    { key: 'my_contests', label: 'My Contests', url: '/player/mycontests'},
    { key: 'your_sponsors', label: 'Your Sponsors', url: '/player/yoursponsors'},
    { key: 'logout', label: 'Log Out', url: '/account/logout'},
];

const CM_MENU_ITEMS: MenuItemType[] = [
    { key: 'account', label: 'Account', url: '/contest_manager/account'},
    { key: 'news', label: 'News', url: '/contest_manager/news'},
    { key: 'newBuild', label: 'Contest Builder', url: '/contest_manager/build_new'},
    { key: 'contest_manager', label: 'Contest Manager', url: '/contest_manager/index'},
    { key: 'your_sponsors', label: 'Your Sponsors', url: '/contest_manager/your_sponsors'},
    { key: 'logout', label: 'Log Out', url: '/account/logout'},
];

const SP_MENU_ITEMS: MenuItemType[] = [
    { key: 'account', label: 'Account', url: '/sponsor/account'},
    { key: 'News', label: 'News', url: '/sponsor/news'},
    { key: 'your_sponsors', label: 'Your Sponsors', url: '/sponsor/your_sponsors'},
    { key: 'logout', label: 'Log Out', url: '/account/logout'},
];

const AD_MENU_ITEMS: MenuItemType[] = [
    { key: 'account', label: 'Account', url: '/admin/account'},
    { key: 'p_news', label: 'News- Player View', url: '/admin/news_player'},
    { key: 'g_news', label: 'News- Guest View', url: '/admin/news_guest'},
    { key: 'welcome_builder', label: 'Welcome Builder', url: '/admin/welcome_builder'},
    { key: 'account_manager', label: 'Accounts Manager', url: '/admin/account_manager'},
    { key: 'contest_manager', label: 'Contest Manager', url: '/admin/contest_manager'},
    { key: 'your_sponsors', label: 'Your Sponsors', url: 'admin/your_sponsors'},
    { key: 'logout', label: 'Log Out', url: '/account/logout'},
];

export { GUEST_MENU_ITEMS, PLAYER_MENU_ITEMS, CM_MENU_ITEMS, SP_MENU_ITEMS, AD_MENU_ITEMS };
