import { Navigate } from 'react-router-dom';
import { useUser } from 'hooks';

const Root = () => {
    const [loggedInUser] = useUser();
    console.log(loggedInUser);
    const getRootUrl = () => {

        let url: string = loggedInUser && loggedInUser.role === "player" ? 'player/mycontests' : 
            loggedInUser && loggedInUser.role === 'contest_manager' ? 'contest_manager/index' : 'welcome';
        return url;
    };

    const url = getRootUrl();

    return <Navigate to={`/${url}`} />;
};

export default Root;
