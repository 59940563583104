import { APICore } from './apiCore';

const api = new APICore();

function login(params: { email: string; password: string; role: string }) {
    // const baseUrl = '/auth/signin/';
    const baseUrl = '/login/';
    return api.create(`${baseUrl}`, params);
}

function logout() {
    const baseUrl = '/logout/';
    return api.create(`${baseUrl}`, {});
}

function signup(params: {
    first_name: string;
    middle_name: string;
    last_name: string;
    birthday: Date;
    country: string;
    state: string;
    county: string;
    city: string;
    zip_code: string;
    building_number: number;
    street_name: string;
    unit: string;
    photo_url: string;
    news_agency1: string;
    agency_zipcode1: string;
    media_type1: string;
    agency_email1: string;
    news_agency2: string;
    agency_zipcode2: string;
    media_type2: string;
    agency_email2: string;
    news_agency3: string;
    agency_zipcode3: string;
    media_type3: string;
    agency_email3: string;
    email: string;
    password: string;
}) {
    const baseUrl = '/auth/signup/';
    return api.create(`${baseUrl}`, params);
}

function forgotPassword(params: { email: string }) {
    const baseUrl = '/forget-password/';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: { email: string }) {
    const baseUrl = '/password/reset/confirm/';
    return api.create(`${baseUrl}`, params);
}

export { login, logout, signup, forgotPassword, forgotPasswordConfirm };
