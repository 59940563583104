import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux } from 'hooks';

// lazy load all the views
const News = React.lazy(() => import('pages/welcome/news/News'));
const ContestStartingSoon = React.lazy(() => import('pages/welcome/ContestStartingSoon'));
const ContestFinished = React.lazy(() => import('pages/welcome/ContestFinished'));

//Player
const MyContests = React.lazy(() => import('pages/player/MyContests'));
const YourSponsors = React.lazy(() => import('pages/player/YourSponsors'));
const Account = React.lazy(() => import('pages/player/Account'));
const EnterContest = React.lazy(() => import('pages/welcome/news/EnterContest'));
const Winners = React.lazy(() => import('pages/welcome/news/Winners'));

//Contest Manager
const ContestManagerAccount = React.lazy(() => import ('pages/contest_manager/Account'));
const ContestManager = React.lazy(() => import ('pages/contest_manager/ContestManager'));
const CmYourSponsors = React.lazy(() => import ('pages/contest_manager/YourSponsors'));
const BuildNew = React.lazy(() => import('pages/contest_manager/BuildNew'));
const TextEmail = React.lazy(() => import('pages/contest_manager/TextEmail'));
const ContestMaint = React.lazy(() => import('pages/contest_manager/ContestMaint'));
const RoundProcessing = React.lazy(() => import('pages/contest_manager/RoundProcessing'));
// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Register = React.lazy(() => import('pages/account/Register'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));

const Logout2 = React.lazy(() => import('pages/account/Logout2'));
const Register2 = React.lazy(() => import('pages/account/Register2'));
const BecomeSponsor = React.lazy(() => import('pages/account/BecomeSponsor'));
const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));


const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

// - other
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const Landing = React.lazy(() => import('pages/landing/'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        { path: '/', element: <Root /> },

        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: 'welcome',
                    element: <LoadComponent component={News} />
                },
                {
                    path: 'conteststarting',
                    element: <LoadComponent component={ContestStartingSoon} />
                },
                {
                    path: 'contestfinished',
                    element: <LoadComponent component={ContestFinished} />
                },
            ]
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'register', element: <LoadComponent component={Register} /> },
                        { path: 'become_sponsor', element: <LoadComponent component={BecomeSponsor} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'register2', element: <LoadComponent component={Register2} /> },
                        // { path: 'register_ads', element: <LoadComponent component={Register2Ads} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                        { path: 'logout2', element: <LoadComponent component={Logout2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/player',
            element: <PrivateRoute roles={'player'} component={Layout} />,
            children: [
                {
                    path: 'news',
                    element: <LoadComponent component={News} />
                },
                {
                    path: 'mycontests',
                    element: <LoadComponent component={MyContests} />
                },
                {
                    path: 'yoursponsors',
                    element: <LoadComponent component={YourSponsors}/>
                },
                {
                    path: 'account',
                    element: <LoadComponent component={Account}/>
                },
                {
                    path: 'contests/:id',
                    element: <LoadComponent component={EnterContest} />
                },
                {
                    path: 'yoursponsors',
                    element: <LoadComponent component={YourSponsors} />
                },
                {
                    path: 'winner/:id',
                    element: <LoadComponent component={Winners} />
                },
                // {
                //     path: 'comingsoon',
                //     element: <LoadComponent component={ComingSoon} />
                // },
                // {
                //     path: 'store',
                //     element: <LoadComponent component={Store} />
                // },
            ],
        },
        {
            // auth protected routes
            path: '/contest_manager',
            element: <PrivateRoute roles={'contest_manager'} component={Layout} />,
            children: [
                {
                    path: 'index',
                    element: <LoadComponent component={ContestManager} />
                },
                {
                    path: 'account',
                    element: <LoadComponent component={ContestManagerAccount} />
                },
                {
                    path: 'news',
                    element: <LoadComponent component={News} />
                },
                {
                    path: 'your_sponsors',
                    element: <LoadComponent component={CmYourSponsors} />
                },
                {
                    path: 'build_new',
                    element: <LoadComponent component={BuildNew} />
                },
                {
                    path: 'text_email',
                    element: <LoadComponent component={TextEmail} />
                },
                {
                    path: 'contest_maint',
                    element: <LoadComponent component={ContestMaint} />
                },
                {
                    path: 'round_processing',
                    element: <LoadComponent component={RoundProcessing} />
                },
            ],
        },
    ]);
};

export { AllRoutes };
