import { AuthActionTypes } from './constants';

export type AuthActionType = {
    type:
    | AuthActionTypes.API_RESPONSE_SUCCESS
    | AuthActionTypes.API_RESPONSE_ERROR
    | AuthActionTypes.FORGOT_PASSWORD
    | AuthActionTypes.FORGOT_PASSWORD_CHANGE
    | AuthActionTypes.LOGIN_USER
    | AuthActionTypes.LOGOUT_USER
    | AuthActionTypes.RESET
    | AuthActionTypes.SIGNUP_USER;
    payload: {} | string;
};

type User = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const authApiResponseSuccess = (actionType: string, data: User | {}): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AuthActionType => ({
    type: AuthActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const loginUser = (email: string, password: string, role: string): AuthActionType => ({
    type: AuthActionTypes.LOGIN_USER,
    payload: { email, password, role },
});

export const logoutUser = (): AuthActionType => ({
    type: AuthActionTypes.LOGOUT_USER,
    payload: {},
});

export const signupSponsor = (
    business_name: string,
    contact_name: string,
    contact_phone_number: string,
    country: string,
    state: string,
    county: string,
    city: string,
    zip: string,
    building: string,
    street_name: string,
    unit: string,
): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: {
        business_name, contact_name, contact_phone_number, country, state, county, city, zip, building, street_name, unit
    },
});

export const signupUser = (
    first_name: string,
    middle_name: string,
    last_name: string,
    birthday: String,
    country: string,
    state: string,
    county: string,
    city: string,
    zip_code: string,
    building_number: string,
    street_name: string,
    unit: string,
    photo_url: string,
    news_agency1: string,
    agency_zipcode1: string,
    media_type1: string,
    agency_email1: string,
    news_agency2: string,
    agency_zipcode2: string,
    media_type2: string,
    agency_email2: string,
    news_agency3: string,
    agency_zipcode3: string,
    media_type3: string,
    agency_email3: string,
    email: string,
    password: string,
): AuthActionType => ({
    type: AuthActionTypes.SIGNUP_USER,
    payload: {
        first_name,
        middle_name,
        last_name,
        birthday,
        country,
        state,
        county,
        city,
        zip_code,
        building_number,
        street_name,
        unit,
        photo_url,
        news_agency1,
        agency_zipcode1,
        media_type1,
        agency_email1,
        news_agency2,
        agency_zipcode2,
        media_type2,
        agency_email2,
        news_agency3,
        agency_zipcode3,
        media_type3,
        agency_email3,
        email,
        password,
    },
});

export const forgotPassword = (username: string): AuthActionType => ({
    type: AuthActionTypes.FORGOT_PASSWORD,
    payload: { username },
});

export const resetAuth = (): AuthActionType => ({
    type: AuthActionTypes.RESET,
    payload: {},
});
